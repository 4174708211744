import { FC } from 'react'
import {useIntl} from "react-intl";

const ToggleHelpDrawer: FC = () => {
  const intl = useIntl()
  // const { setIsOpen } = useTour()

  const open = () => {
    window.open('https://racasf.com/api/wapp', '_blank')
  }

  return <button
    onClick={open}
    className='engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0'>
    {intl.formatMessage({ id: 'MENU.HELP' })}
  </button>
}

export {ToggleHelpDrawer}
