import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ERoutes from "../core/enums/ERoutes.ts";

const PrivateRoutes = () => {
  const CreditsRoutes = lazy(() => import('../modules/credits/Credits.tsx'))
  const CodesRoutes = lazy(() => import('../modules/codes/Router.tsx'))
  const TemplateRoutes = lazy(() => import('../modules/templates/Router.tsx'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path={ERoutes.AUTH_ALL} element={<Navigate to={ERoutes.DASHBOARD} />} />

        {/* Pages */}
        <Route path={ERoutes.DASHBOARD} element={<DashboardWrapper />} />

        <Route
          path={ERoutes.CREDITS}
          element={
            <SuspensedView>
              <CreditsRoutes />
            </SuspensedView>
          } />

        <Route
          path={ERoutes.TEMPLATES_ALL}
          element={
            <SuspensedView>
              <TemplateRoutes />
            </SuspensedView>
          } />

        <Route
          path={ERoutes.CODES_ALL}
          element={
            <SuspensedView>
              <CodesRoutes />
            </SuspensedView>
          } />
        {/*<Route*/}
        {/*  path={ERoutes.TALENTS_ALL}*/}
        {/*  element={*/}
        {/*    <SuspensedView>*/}
        {/*      <TalentsRouter />*/}
        {/*    </SuspensedView>*/}
        {/*  } />*/}

        {/* Page Not Found */}
        {/*<Route path='*' element={<Navigate to='/error/404' />} />*/}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
