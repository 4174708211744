enum ERoutes {
  HOME = '/',

  AUTH = '/auth',
  AUTH_ALL = '/auth/*',
  AUTH_SIGNUP = '/auth/signup',
  AUTH_LOGIN = '/auth/login',
  LOGOUT = '/logout',
  AUTH_PASSWORD_CHANGE = '/auth/password-recovery',
  AUTH_ACCOUNT_ACTIVATION = '/auth/account-activation',
  OAUTH = '/oauth/:provider',

  AUTH_PASSWORD_RECOVERY_REQUEST = '/auth/forgot-password',

  ERROR_ALL = '/error/*',
  ALL = '/*',

  DASHBOARD = '/dashboard',

  TEMPLATES = '/templates',
  TEMPLATES_ALL = '/templates/*',
  TEMPLATES_INFORMATION = '/templates/:id/information',
  TEMPLATES_OPTIONS = '/templates/:id/options',

  CODES = '/codes',
  CODES_ALL = '/codes/*',
  CODES_INFORMATION = '/codes/:id/information',
  CODE_OPTIONS = '/codes/:id/options',
  CODES_SCANS = '/codes/:id/scans',
  CODES_PASSES = '/codes/:id/passes',
  CODES_NOTIFICATIONS = '/codes/:id/notifications',
  CODES_UPDATES = '/codes/:id/updates',
  CODES_USES = '/codes/:id/uses',

  CREDITS = '/credits',

  USERS = '/users',
  USERS_ALL = '/users/*',
  USERS_INFORMATION = '/users/:id/information',

  ROLES = '/roles',
  ROLES_ALL = '/roles/*',
  ROLES_INFORMATION = '/roles/:id/information',

  MY_COMPANY = '/my-company',
}

export default ERoutes;
