import {GetSelectedCompany} from "../../../../utils/selectCompany.ts";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice.ts";
import {Dispatch} from "redux";
import {RACARequest} from "../../../Request.ts";
import requestMiddlewares from "../../../requestMiddlewares.ts";
import moment from "moment";

export default async function (
  dispatch: Dispatch,
) {
  const cid = GetSelectedCompany();
  if (!cid) {
    return {
      statusCode: 500,
      data: {
        message: 'No company selected'
      }
    }
  }

  dispatch(startLoading());

  const request = new RACARequest('credits', 'get_movement_graph')
    .setPathValues('COMPANY_ID', cid)
    .setQueryParameters({
      fromDate: moment().subtract(1, 'month').format('YYYYMMDD'),
      toDate: moment().format('YYYYMMDD'),
      inbound: 'false',
      outbound: 'true',
    })

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
