import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { ToolbarWrapper } from '../../../_metronic/layout/components/toolbar'
import { Content } from '../../../_metronic/layout/components/content'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store.ts";
import NoCompaniesView from "../../../_metronic/partials/NoCompaniesView.tsx";
import getDashboard from "../../core/api/requests/companies/dashboard/getDashboard.ts";
import {BalanceWidget} from "./BalanceWidget.tsx";
import {ScansGraph} from "./ScansGraph.tsx";
import CustomGraph from "./CustomGraph.tsx";
import getMovementGraphRequest from "../../core/api/requests/companies/credits/getMovementGraphRequest.ts";
import IRealTimeData from "../../core/interfaces/IRealTimeData.ts";

const DashboardPage: FC = () => {
  const intl = useIntl()

  const companies = useSelector((state: RootState) => state.companies)

  const [ dashboardData, setDashboardData ] = useState<any>({})

  const [ movementGraph, setMovementGraph ] = useState<IRealTimeData[]>([])

  const dispatch = useDispatch()

  const getData = async () => {
    const response = await getDashboard(dispatch)

    if (response.statusCode === 200) {
      setDashboardData(response.data)
    }
  }

  const getMovementGraph = async () => {
    const response = await getMovementGraphRequest(dispatch)
    if (response.statusCode !== 200) return

    setMovementGraph(response.data)
  }

  useEffect(() => {
    getData()
    getMovementGraph()
  }, [companies.selected])

  return <>
    <ToolbarWrapper/>
    <Content>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 '>
          <BalanceWidget
            className='h-md-100'
            total={dashboardData?.balance?.total || 0}
            available={dashboardData?.balance?.available || 0}
            pending={dashboardData?.balance?.pending || 0}
          />
        </div>
      </div>

      <div className='row g-5 gx-xxl-8'>
        <div className='col-12'>
          <CustomGraph
            name={intl.formatMessage({id: 'TITLE.CREDIT_MOVEMENTS'})}
            description={intl.formatMessage({id: 'DESCRIPTION.CREDIT_MOVEMENTS'})}
            data={movementGraph}
            xSymbol={'€'}
            className='card-xxl-stretch mb-xl-3'
          />
        </div>
      </div>

      <div className='row g-5 mt-1 gx-xxl-8'>
        <div className='col-12'>
          <ScansGraph
            data={dashboardData?.scans || []}
            className='card-xxl-stretch mb-xl-3'
          />
        </div>
      </div>
    </Content>
  </>
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  const selectedCompany = useSelector((state: RootState) => state.companies)

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      {selectedCompany.selected && <DashboardPage/>}

      {!selectedCompany.selected &&
        <NoCompaniesView/>
      }
    </>
  )
}

export {DashboardWrapper}
