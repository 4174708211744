import IRealTimeData from "../../core/interfaces/IRealTimeData.ts";
import React, {useEffect, useRef} from "react";
import {useThemeMode} from "../../../_metronic/partials";
import ApexCharts, {ApexOptions} from 'apexcharts'
import {number, string} from "yup";

export interface IComponent {
  data: IRealTimeData[];
  name: string;
  description?: string;
  className?: string
  xSymbol?: string
}

export interface ISerie {
  name: string;
  data: any[];
}

export default function (props: IComponent) {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const { mode } = useThemeMode()

  const options = (
    categories: string[],
    series: ISerie[],
  ): ApexOptions => {
    return {
      series,
      noData: {
        text: 'No data',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
        },
      },
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        stacked: true,
        height: 300,
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        foreColor: '#999',
        background: 'transparent',
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: true,
        position: 'back',
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: false,
        position: 'left',
        offsetY: 0,
        floating: true,
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: '12px',
        }
      },
      fill: {
        opacity: 0.8,
      },
      xaxis: {
        type: 'category',
        labels: {
          show: true,
        },
        categories,
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          formatter: (value): string | string[] => {
            return `${value.toFixed(3)} ${props.xSymbol || ''}`
          }
        },
      }
    }
  }

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const cats: any = {}
    const groups: string[] = []

    props.data.forEach((item) => {
      cats[item.xAxis] = {
        ...cats[item.xAxis],
        [item.name]: item.value
      }

      if (!groups.includes(item.name)) {
        groups.push(item.name)
      }
    })

    const categories = Object.keys(cats)

    const series: ISerie[] = groups.map((group) => {
      return {
        name: group,
        data: categories.map((c) => {
          return {
            x: c,
            y: cats[c][group] || 0,
            meta: {
              count: categories.length,
            },
          }
        })
      }
    })

    const ch = new ApexCharts(
      chartRef.current,
      options(categories, series)
    )
    if (ch) {
      ch.render()
    }

    return ch
  }

  useEffect(() => {
    const ch = refreshChart()
    return () => {
      if (ch) {
        ch.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, props.data])

  return <div className={`card ${props.className}`}>
    <div className='card-header border-0 py-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>{props.name}</span>

        {props.description &&
          <span className='text-muted fw-semibold fs-7'>{props.description}</span>
        }
      </h3>
    </div>

    <div className='card-body d-flex flex-column'>
      <div ref={chartRef} className=''></div>
    </div>
  </div>
}

