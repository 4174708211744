import {useEffect, useState} from 'react'
import {ILayout, useLayout} from '../../core'
import getVersion from "../../../../app/utils/version.ts";
import {useIntl} from "react-intl";

const Footer = () => {
  const intl = useIntl()
  const {config} = useLayout()
  const [ version, setVersion ] = useState<string>('')

  useEffect(() => {
    updateDOM(config)

    getVersion().then((v) => setVersion(v))
  }, [config])

  return (
    <>
      <div className='text-gray-900 order-2 order-md-1'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <p className='text-gray-800 text-dark d-inline-block m-0'>dPass - <strong>{version}</strong> - {intl.formatMessage({ id: 'NOTES.BETA_APP' })}
          <a className={'text-hover-primary text-dark'} href={'mailto:info@racasf.com?Subject=Ayuda%20con%20dPass'}> {intl.formatMessage({ id: 'BUTTON.HERE' })}.</a>
        </p>
      </div>

      {/*<ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>*/}
      {/*  <li className='menu-item'>*/}
      {/*    <a href='https://keenthemes.com/' target='_blank' className='menu-link px-2'>*/}
      {/*      About*/}
      {/*    </a>*/}
      {/*  </li>*/}

      {/*  <li className='menu-item'>*/}
      {/*    <a href='https://devs.keenthemes.com/' target='_blank' className='menu-link px-2'>*/}
      {/*      Support*/}
      {/*    </a>*/}
      {/*  </li>*/}

      {/*  <li className='menu-item'>*/}
      {/*    <a*/}
      {/*      href='https://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469'*/}
      {/*      target='_blank'*/}
      {/*      className='menu-link px-2'*/}
      {/*    >*/}
      {/*      Purchase*/}
      {/*    </a>*/}
      {/*  </li>*/}
      {/*</ul>*/}
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
